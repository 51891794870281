// core.module.ts
// CoreModule contains code that will be used to instantiate app and load some core functionality.
import * as tslib_1 from "tslib";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatBadgeModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatRippleModule, MatTabsModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatButtonToggleModule, MatGridListModule, MatIconModule, MatInputModule, MatMenuModule, MatProgressSpinnerModule, MatSelectModule, MatSnackBarModule, MatTableModule, MatToolbarModule, MatTooltipModule, MatSlideToggleModule, MatListModule, MatChipsModule } from '@angular/material';
// https://github.com/FortAwesome/angular-fontawesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
var modules = [
    BrowserAnimationsModule, FormsModule, HttpClientModule, MatBadgeModule,
    MatButtonModule, MatCardModule, MatCheckboxModule, MatRippleModule, MatTabsModule, MatDialogModule,
    MatDividerModule, MatExpansionModule, MatFormFieldModule, MatButtonToggleModule, MatGridListModule,
    MatIconModule, MatInputModule, MatMenuModule, MatProgressSpinnerModule, MatSelectModule,
    MatSnackBarModule, MatTableModule, MatToolbarModule, MatTooltipModule, MatSlideToggleModule, MatListModule,
    ReactiveFormsModule, RouterModule, FontAwesomeModule, MatChipsModule
];
var CoreModule = /** @class */ (function (_super) {
    tslib_1.__extends(CoreModule, _super);
    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    function CoreModule(parentModule) {
        return _super.call(this, parentModule) || this;
    }
    return CoreModule;
}(EnsureModuleLoadedOnceGuard));
export { CoreModule };
